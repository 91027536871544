/* Main */
body {
    //padding-top: 52px;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003974',endColorstr='#003974',GradientType=1);
    background: #003974;
    background: -moz-linear-gradient(left, #003974, #6CC2C9, #003974);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #003974), color-stop(50%, #6CC2C9), color-stop(100%, #003974));
    background: -webkit-linear-gradient(left, #003974, #6CC2C9, #003974);
    background: -o-linear-gradient(left, #003974, #6CC2C9, #003974);
    background: -ms-linear-gradient(left, #003974, #6CC2C9, #003974);
    background: linear-gradient(to right, #003974, #6cc2c9, #003974);
}

/*a {
    text-decoration: none;
}*/
.weekLabel {
    text-decoration: underline;
}
.weekLabel:hover {
    color: #0d6efd !important;
}

.flex {
    margin: 0 auto;
}

.flex > .whitebg > .container {
    padding-top: 30px;
}

.whitebg {
    background: #FFF;
    padding-bottom: 10px;
}

.jumbotron {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0px;
}

.keycolumn {
    font-weight: bold;
    color: forestgreen;
}

h1 { /* We override H1 to look like H3 for Moz */
    font-size: 24px !important;
}

/* Navigation */
.navbar-brand {
    //background-image: url(@program-logo-horz-rgb-rev-sm);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100px;
}

.navbar-dark {
    background-color: #003974;
    border-color: #00468e;
    color: white;
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-nav > li > a {
    color: #fff;
}

.navbar-dark .navbar-nav > .active > a,
.navbar-dark .navbar-nav > .active > a:hover,
.navbar-dark .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #002347;
}

ul.nav a:hover {
    background-color: #002347 !important;
    color: #e5e5e5 !important;
}

.navbar-dark .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    background-color: #002347 !important;
}

/* Screen Specific Changes */
@media screen and (min-width: 768px) {
    /*.footer {
        background-image: url(https://frc-cdn.firstinspires.org/eventweb_common/FIRST_Tagline_Footer.png);
    }*/

    /*.navbar-brand {
        background-image: url(@program-logo-horz-rgb-rev-sm);
        text-indent: 170px;
    }*/
}

@media screen and (max-width: 767px) {
    /*.footer {
        background-image: url(https://frc-cdn.firstinspires.org/eventweb_common/FIRSTLogos/FIRST_Vert_RGB_rev_md.png);        
        background-position: center top;
        margin: 10px 0 0 0 !important;
        width: auto !important;
    }*/

    .footer-text {
        padding-top: 105px;
        text-align: center !important;
        float: none !important;
    }

    .navbar-brand {
        //background-image: url(https://frc-cdn.firstinspires.org/eventweb_common/FIRST_Logo_Reverse_Horizontal-notext.png);
        background-size: 65px;
        font-size: 120%;
        background-position: left 10px center;
    }

    .navbar-dark .navbar-nav .open .dropdown-menu > li > a {
        color: white;
    }

    #login-other-options > a {
        color: white;
    }
}

/* Footer */
.footer {
    margin: 0 auto;
    padding: 5px 5px 20px 5px;
    background-repeat: no-repeat;
    background-origin: content-box;
    min-height: 160px;
    height: auto;
    background-color: transparent !important;
    overflow: hidden !important;
}

.footer-box {
    background-color: #231F20;
    width: 100%;
}

.footer-text {
    //float: right;
    text-align: right;
    margin-top: 10px;
    min-height: 145px;
    //color: #7e7e7e;
}

.footer-text > a,
.footer-text > a:hover,
.footer-text > a:focus {
    color: #7e7e7e;
}

.footer-links,
.footer-links a,
.footer-links a:hover,
.footer-links a:focus {
    color: white;
    font-size: 12px;
}

/* Top-page Color Strip */
.color-strip .fgreen {
    background-color: #00a651;
}

.color-strip .fred {
    background-color: #ed1c24;
}

.color-strip .forange {
    background-color: #f57e25;
}

.color-strip .fblue {
    background-color: #009cd7;
}

.color-strip {
    height: 10px;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.color-strip div {
    display: block;
    height: 100%;
    width: 33.333%;
    float: left;
}

/* Event Status Labels */
.label.label-statecode {
    min-width: 30px !important;
    display: inline-block !important;
}

.label-default.label-nodata {
    background-color: #a3a3a3;
}

/* Login / Register Dropdown */
#login-dropdown-list .dropdown-menu {
    min-width: 300px;
    padding: 15px;
}

#login-dropdown-list #forgot-password-link,
#login-dropdown-list #signup-link {
    margin-top: 10px;
}

#login-dropdown-list .btn {
    margin-top: 5px;
}

#login-dropdown-list input {
    margin: 0 0 -1px;
    border-radius: 0px;
    position: relative;
    z-index: 1;
}

#login-dropdown-list input:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#login-dropdown-list input:last-of-type {
    margin-bottom: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#login-dropdown-list label {
    padding-left: 5px;
}

#login-dropdown-list a {
    cursor: pointer;
}

#login-other-options > a:hover {
    background-color: transparent !important;
    color: #337ab7 !important;
}

info {
    background-color: #d9edf7;
}
danger {
    background-color: #f2dede;
}

/* Table Customization */
td.info {
    background-color: #d9edf7;
}

td.danger {
    background-color: #f2dede;
}

th.warning {
    background-color: #fcf8e3;
}

td.warning {
    background-color: #fcf8e3;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #F2E679;
}

.tablesorter-default .tablesorter-processing {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-image: url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=') !important;
}

.tablesorter-default thead .headerSortUp,
.tablesorter-default thead .tablesorter-headerSortUp,
.tablesorter-default thead .tablesorter-headerAsc {
    background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
    border-bottom: #000 2px solid;
}

.tablesorter-default thead .headerSortDown,
.tablesorter-default thead .tablesorter-headerSortDown,
.tablesorter-default thead .tablesorter-headerDesc {
    background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
    border-bottom: #000 2px solid;
}

.tablesorter-default .header,
.tablesorter-default .tablesorter-header {
    background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
    background-position: center right;
    background-repeat: no-repeat;
    cursor: pointer;
    white-space: normal;
    padding: 4px 20px 4px 4px;
}

.community-img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px;
    height: 20px;
    width: 20px;
}

.community-img-link {
    background: #9dea9da1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px;
    height: 20px;
    width: 20px;
}